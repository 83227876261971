<template>
  <div class="MERCH userAudit">
    <div class="merch-card">
      <!-- 搜索列 -->
      <div class="merch-search">
        <div class="search-box">
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">关键字:</div> -->
            <el-input
              placeholder="请输入单位名称/简称"
              v-model="queryParams.name"
              maxlength="200"
              onkeyup="this.value=this.value.replace(/[ ]/g,'')"
            ></el-input>
          </div>
          <!-- 企业名称 -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">关键字:</div> -->
            <el-input
              placeholder="请输入法人姓名/联系电话"
              v-model="queryParams.keywords"
              maxlength="18"
              onkeyup="this.value=this.value.replace(/[ ]/g,'')"
            ></el-input>
          </div>
          <!-- 类型选择 -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">客户类型:</div> -->
            <el-select
              clearable
              v-model="queryParams.user_type"
              placeholder="请选择客户类型"
            >
              <el-option
                v-for="item in userType"
                :key="item.id"
                :label="item.type_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 审核状态 -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">审核状态:</div> -->
            <el-select
              v-model="queryParams.status"
              placeholder="请选择审核状态"
            >
              <el-option
                v-for="item in ShType"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 区域选择 -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">所在地区:</div> -->
            <el-cascader
              v-model="queryParams.AreaArr"
              :options="AreaList"
              :props="propsValue"
              clearable
              placeholder="请选择所在地区"
            ></el-cascader>
          </div>
          <div class="sch-1 sch-2" style="width: 400px">
            <el-date-picker
              v-model="queryParams.datelist"
              type="datetimerange"
              range-separator="至"
              start-placeholder="请选择审核开始日期"
              end-placeholder="请选择审核结束日期"
              unlink-panels
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
            />
          </div>
          <!-- 审核人 -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">关键字:</div> -->
            <el-input
              placeholder="请输入审核人"
              v-model="queryParams.auditor"
              onkeyup="this.value=this.value.replace(/[ ]/g,'')"
              clearable
            ></el-input>
          </div>
          <!-- 搜索 -->
          <div class="sch-1 sch-2">
            <el-button
              type="primary"
              size="mini"
              @click="toSearch1"
              style="width: 80px"
            >
              查询
            </el-button>
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <div
        :style="`height: ` + mainHright + `px`"
        class="merch-table scorr-roll"
      >
        <div>
          <el-table
            :data="usersData.data"
            border
            style="width: 100%"
            :header-cell-style="{ background: '#F2F3F5' }"
            v-loading="loading"
          >
            <el-table-column align="center" label="编号" width="70">
              <template slot-scope="scope">
                <p>{{ scope.row.index }}</p>
              </template>
            </el-table-column>
            <el-table-column
              prop="create_time"
              label="提交时间"
              align="center"
              show-overflow-tooltip
            />
            <el-table-column
              prop="type_name"
              label="审核类型"
              align="center"
              width="120"
            />
            <el-table-column
              prop="enterprise"
              label="单位名称"
              align="center"
              show-overflow-tooltip
            />
            <el-table-column
              prop="shop_name"
              label="单位简称"
              align="center"
              show-overflow-tooltip
            />
            <el-table-column
              prop="legal_entity"
              label="法人姓名"
              align="center"
            />
            <el-table-column
              prop="mobile"
              label="联系电话"
              align="center"
              width="150"
            />
            <el-table-column
              prop="address"
              label="企业注册地"
              align="center"
              width="200"
            >
              <template slot-scope="scope">
                <div>
                  {{ scope.row.province_name }},{{ scope.row.city_name }},{{
                    scope.row.district_name
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="user_type_name"
              label="会员类型"
              align="center"
            />
            <el-table-column prop="auditor" label="审核人" align="center" />
            <el-table-column
              prop="examine_time"
              label="审核时间"
              align="center"
              width="270"
            />
            <el-table-column
              prop="status_name"
              label="审核状态"
              align="center"
            />
            <el-table-column
              prop="address"
              label="操作"
              align="center"
              width="170"
            >
              <template slot-scope="{ row }">
                <div>
                  <!--  -->
                  <span
                    class="btsed"
                    @click="toMessage(row)"
                    v-if="
                      row.status_name == '待审核' &&
                      allJurisdiction.userAudit152
                    "
                  >
                    立即审核
                  </span>
                  <span
                    class="btsed"
                    @click="todetails(row)"
                    v-if="
                      row.status_name != '待审核' &&
                      allJurisdiction.userAudit153
                    "
                  >
                    详情
                  </span>
                  <span
                    class="btsed"
                    @click="updialogVis(row)"
                    v-if="row.status == 3"
                  >
                    发送短信
                  </span>
                  <!-- <span  class="btsed" @click="approved(row)">审核记录</span> -->
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 分页 -->
      <div class="merch-page">
        <el-pagination
          background
          :current-page="usersData.current_page"
          @current-change="handleCurrentChange"
          :page-size="usersData.per_page"
          layout="total, prev, pager, next, jumper"
          :total="usersData.total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 发送短信 -->
    <el-dialog
      title="发送短信"
      :visible.sync="dialogVisible"
      width="400px"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div>
        <div style="margin-bottom: 5px">签署人</div>
        <el-input
          v-model="diagItem.username"
          placeholder="请输入签署人"
          style="width: 300px"
          clearable
        ></el-input>
      </div>
      <div style="margin: 10px 0px">
        <div style="margin-bottom: 5px">签署人手机号</div>
        <el-input
          v-model="diagItem.mobile"
          placeholder="请输入手机号"
          style="width: 300px"
          clearable
        ></el-input>
      </div>
      <div class="futle" style="margin: 10px 0px">
        手机号必须是签署人本人手机号，否则无法签署。
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
        <el-button type="primary" @click="determine" :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import { reviewList, userTypelist } from "@/api/userqualiy.js";
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("Audit"); //vuex公共库
import {
  sendSms, //经营范围设置发送短信
  getAgentInfo, //获取代理人信息
} from "@/api/apiAll/phpUrl.js";
export default {
  data() {
    return {
      loading: false,
      searchData: {},
      activeName: 0,
      IsAddEnterprise: false,
      IsAddqualification: false,
      value: "",
      propsValue: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true,
      }, //级联选择器配置项

      queryParams: {
        status: 0, //资质审核状态:1待确认;2已确认(待审核);3已完成;4未通过;5已删除
        keywords: "", // 单位名称 法人 手机号关键字筛选
        user_type: "", // 资质类型id
        province_id: "", // 省id
        city_id: "", // 市id
        district_id: "", // 区id
        page: 1,
        name: "",
        auditor: "",
        datelist: [],
        queryParams: [],
      },
      usersData: {
        data: [],
      },

      dialogVisible: false, //发送短信弹窗
      diagItem: {
        userId: "",
        username: "", //用户名称
        name: "", //用户名称
        mobile: "", //手机号
      },

      // 审核状态
      ShType: [
        {
          name: "待审核",
          type: 0,
        },
        {
          name: "待用户签署",
          type: 3,
        },
        {
          name: "已完成",
          type: 1,
        },
        {
          name: "已拒绝",
          type: 2,
        },
      ],
      // 客户类行
      KhType: [
        {
          name: "经营企业（批发）",
          type: 1,
        },
        {
          name: "经营企业（连锁）",
          type: 3,
        },
        {
          name: "生产企业",
          type: 2,
        },
      ],
    };
  },

  computed: {
    ...commonIndex.mapState([
      "AreaList",
      "userType",
      "mainHright",
      "allJurisdiction",
      "detailsSeach",
    ]),
  },
  created() {
    //获取存储查询
    let path = this.$route.path;
    if (this.$store.getters.routData[path]) {
      Object.assign(
        this.queryParams,
        this.$store.getters.routData[path].queryParams
      );
    }
    this.getList();
  },
  methods: {
    ...mapActions([
      "postmemberAuditlist",
      "getUserType",
      "postUserQualificationMemberAuditReviewList",
    ]),
    ...commonIndex.mapMutations(["changeSeach"]),
    toSearch1() {
      this.queryParams.page = 1;
      this.toSearch();
    },
    // async getuserTypelist() {
    //   let res = await this.getUserType();
    //   if (res&&res.code == "200") {
    //     this.KhType = res.data;
    //   }
    // },
    // 审核记录
    approved(row) {
      this.$router.push({
        path: "/approvedMemo",
        query: { user_id: row.user_id, id: row.ID, type: "user" },
      });
    },
    async getList() {
      this.queryParams.name = this.queryParams.name.replace(/\s*/g, "");
      this.queryParams.keywords = this.queryParams.keywords.replace(/\s*/g, "");
      var data1 = {
        keywords: this.queryParams.keywords,
        status: this.queryParams.status,
        user_type: this.queryParams.user_type,
        page: this.queryParams.page,
        name: this.queryParams.name,
        auditor: this.queryParams.auditor,
        start_time: "",
        end_time: "",
      };
      if (this.queryParams.AreaArr) {
        data1.location = {
          province: this.queryParams.AreaArr[0] || "",
          city: this.queryParams.AreaArr[1] || "",
          district: this.queryParams.AreaArr[2] || "",
        };
      }
      if (this.queryParams.datelist) {
        data1.start_time = this.queryParams.datelist[0];
        data1.end_time = this.queryParams.datelist[1];
      } else {
        data1.start_time = "";
        data1.end_time = "";
      }
      this.loading = true;
      let res = await this.postUserQualificationMemberAuditReviewList(data1);
      this.loading = false;
      if (res && res.data) {
        let router = this.$router.history.current.path.substr(1);
        let save = data1;
        save.datelist = this.queryParams.datelist;
        save.AreaArr = this.queryParams.AreaArr;
        save.router = router;
        this.changeSeach(save);
        res.data.forEach((v, i) => {
          let index = (res.current_page - 1) * res.per_page + i + 1;
          this.$set(v, "index", index);
        });
      }
      this.usersData = res;
      this.setquery();
    },
    //存储查询状态
    setquery() {
      var path = this.$route.path;
      var data = {};
      data[path] = {
        queryParams: this.queryParams,
      };
      this.$store.dispatch("routing/querying", data);
    },
    async handleCurrentChange(e) {
      this.queryParams.page = e;
      this.getList();
    },
    async toSearch() {
      this.queryParams.page = 1;
      this.getList();
    },
    // tag标签切换
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    // 前往审核
    toMessage(row) {
      if (row.type == 1) {
        var id = row.user_log_id;
        this.$router.push({
          path: "/toexamine",
          query: {
            user_id: row.user_id,
            id: id,
            review_id: row.id,
            typeName: row.type_name,
          },
        });
      } else {
        var id = row.user_qualify_id;
        this.$router.push({
          path: "/usersMessage",
          query: {
            user_id: row.user_id,
            id: id,
            review_id: row.id,
            typeName: row.type_name,
          },
        });
      }
    },
    //详情
    todetails(row) {
      if (row.type == 1) {
        var id = row.user_log_id;
        var id_row = row.id;
        this.$router.push({
          path: "/userdetails",
          query: { id: id, id_row: id_row },
        });
      } else {
        var id = row.user_qualify_id;
        this.$router.push({
          path: "/usersMessage",
          query: { user_id: row.user_id, id: id },
        });
      }
    },
    /** 发送短信弹窗 */
    async updialogVis(row) {
      this.diagItem.userId = row.user_id;
      let res = await getAgentInfo({
        userId: row.user_id,
      });
      if (res.code == "200") {
        Object.assign(this.diagItem, res.data);
        this.diagItem.name = res.data.username;
        this.dialogVisible = true;
      }
    },
    async determine() {
      if (this.diagItem.username == "")
        return this.$message.error("签署人不能为空");
      if (this.diagItem.mobile == "")
        return this.$message.error("签署人手机号不能为空");
      this.loading = true;
      let res = await sendSms(this.diagItem);
      this.loading = false;
      if (res.code == "200") {
        this.$message.success("发送成功");
        this.dialogVisible = false;
        this.getList();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  // .el-input {
  //   width: 300px !important;
  // }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;
      .search-box {
        display: flex;
        flex-wrap: wrap;
      }
      .sch-1 {
        margin-top: 10px;
        width: 250px;
        // min-width: 250px;
        display: flex;

        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
      .sch-2 {
        margin-right: 2%;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
      height: 77%;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}
::v-deep .el-select {
  width: 250px;
}
::v-deep .el-table {
  border-radius: 8px;
}
::v-deep .el-cascader {
  width: 250px;
}
</style>
